<template>
  <page-main
    title="Time for your Technical or 'Hard' Skills."
    :aside="[
      'List out your skill set - what concrete skills do you have?',
      'Once you have listed your skills, drag and drop to indicate your skill level.',
      'We suggest \'Advanced\', \'Familiar\', and \'Basic\', but you can change the skill level labels to better fit your resume.',
      'We also want to get your licenses and certifications down. Not every career field has these, so don\'t worry if it doesn\'t apply to you.'
    ]"
    nextText="The so-called 'Soft' skills"
    buttonTabIndex="3"
    eventName="nav"
    @nav="nav('/SoftSkills')"
    @save="save()"
  >
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
         <r-multi-select-levels
            label="Hard Skills"
            titleText="Hard or Technical Skills:"
            :optionsList="baseHardSkills"
            :selected.sync="hardSkills"
            :categoryLabels.sync="hardSkillLabels"
            tabindex="1"
            autofocus
          ></r-multi-select-levels>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="Licenses and Certifications"
            titleText="Licenses and Certifications:"
            :optionsList="[]"
            :selected.sync="licensesCerts"
            tabindex="2"
          ></r-multi-select>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import * as Display from "../utility/display";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";

// @ is an alias to /src
export default {
  name: "HardSkills",
  data: () => ({}),
  components: {},
  methods: {
    save() {
      this.findDisplayValues();
      this.saveHardSkills();
      this.saveLicenseCert();
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    ...mapActions("hardSkill", ["saveHardSkills"]),
    ...mapActions("licenseCert", ["saveLicenseCert"]),
    findDisplayValues() {
      let hardDisplay = Display.BuildCommaString("", this.hardSkills);
      let licensesDisplay = Display.BuildCommaString("", this.licensesCerts);

      this.hardSkillsDisplay = hardDisplay;
      this.licensesCertsDisplay = licensesDisplay;

      return [hardDisplay, licensesDisplay];
    }
  },
  mounted() {},
  computed: {
    ...mapFields("hardSkill", [
      "hardSkills",
      "hardSkillsDisplay",
      "hardSkillLabels",
      "baseHardSkills"
    ]),
    ...mapFields("licenseCert", ["licensesCerts", "licensesCertsDisplay"]),
    displayValue: function() {
      return this.findDisplayValues();
    }
  }
};
</script>
